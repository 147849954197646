import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useSearchParams
} from "react-router-dom";
import { useState, useEffect, useRef } from 'react';

import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate replace to="/oauthcallback" />} />
            <Route path="/oauthcallback" element={<OauthCallback />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

function OauthCallback() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [code, setCode] = useState("")
  const uniwebViewRef = useRef(null)
  const [messagePage, setMsg] = useState("Requesting Access ....")
  
  useEffect(() => {
    if(searchParams.has("code")) {
      const paramCode = searchParams.get("code")

      setCode(paramCode)
      if(code != "") {
        console.log("code : ", code)
        uniwebViewRef.current.addEventListener('click', handleClickUniWeb)
        uniwebViewRef.current.click()
        setMsg("Requesting Access ....")
      } else {
        setMsg("Param is empty")
      }
    } else {
      console.log("Not found code param")
      setMsg("Not found param code")
    }
  }, [code]);

  function handleClickUniWeb() {
  }

  return (
    <div>
      <a ref={uniwebViewRef} href={`uniwebview://action?code=${code}`}></a>
      <h1>{messagePage}</h1>
    </div>
  );
}

function NotFound() {
  return <h1>404 Not Found</h1>
}

export default App;